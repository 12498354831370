import React from "react";
import Layout from "../components/Layout";
import { graphql } from "gatsby";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import SocialMediaButtons from "../components/SocialMediaButtons";
import SEO from "../components/SEO";
import ButtonOutlined from "../components/ButtonOutlined";
import SideBar from "../components/SideBar";
import CategoriesRibbon from "../components/CategoriesRibbon";
import Img from "gatsby-image";
import { getFluidGatsbyImage } from "../utils/getFluidGatsbyImage";

export const ArticleQuery = graphql`
  query($slug: String!) {
    contentfulArticle(slug: { eq: $slug }) {
      title
      slug
      publishedDate
      articleCover {
        title
        fluid(maxWidth: 768) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      categories {
        title
        slug
      }
      description
      body {
        json
      }
    }
  }
`;

const ArticleTemplate = (props) => {
  const options = {
    renderNode: {
      [BLOCKS.OL_LIST]: (node, children) => (
        <ul className="list-decimal text-white pl-8">{children}</ul>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <ul className="list-disc text-white pl-6">{children}</ul>
      ),
      // [BLOCKS.EMBEDDED_ASSET]: (node) => (
      //   <div className="mb-2 flex justify-center">
      //     <img
      //       alt={node.data.target.fields.title["pt-BR"]}
      //       src={`${node.data.target.fields.file["pt-BR"].url}?w=768&q=50`}
      //       className="h-full object-center shadow-md rounded-lg"
      //     />
      //   </div>
      // ),
      [BLOCKS.EMBEDDED_ASSET]: (node) => {
        const { file, title } = node.data.target.fields;
        const image = {
          file: file["pt-BR"],
        };
        const fluidProps = getFluidGatsbyImage(image, { maxWidth: 720 });
        return (
          <div className="mb-2">
            {/* {JSON.stringify(fluidProps, null, 4)} */}
            <Img
              fluid={fluidProps}
              alt={title["pt-BR"]}
              title={title["pt-BR"]}
              className="object-center shadow-md rounded-lg"
            />
          </div>
        );
      },
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <p className="text-white text-base pb-2 text-justify">{children}</p>
      ),
      [BLOCKS.HEADING_1]: (node, children) => (
        <h1 className="text-center text-white text-4xl font-bold ">
          {children}
        </h1>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <div className="">
          <h2 className="text-white text-3xl font-semibold">{children} </h2>
          <hr />
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"
          ></script>
          {/* <!-- CS_article_hor_disp_resp --> */}
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-4852256985005782"
            data-ad-slot="1406726411"
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
          <script>{`(adsbygoogle = window.adsbygoogle || []).push({});`}</script>
        </div>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <h3 className="text-white text-2xl font-semibold ">{children} </h3>
      ),
      [BLOCKS.HEADING_4]: (node, children) => (
        <h3 className="text-white text-2xl font-semibold ">{children} </h3>
      ),
      [INLINES.HYPERLINK]: (node, children) => {
        // If you are using contenful.js sdk, the referenced entry is resolved
        // automatically and is available at `node.data.target`.
        return (
          <a
            href={node.data.uri}
            className="text-yellow-400 hover:underline cursor-pointer"
            target="_blank"
            rel="noopener noreferrer"
          >
            {children}
          </a>
        );
        // if (node.data.uri.includes("gatetobrazil")) {
        //   return (
        //     <Link to={node.data.uri} target="_blank" rel="noopener noreferrer">
        //       {children}
        //     </Link>
        //   )
        // } else {
        //   return (
        //     <MaterialUILink
        //       href={node.data.uri}
        //       target="_blank"
        //       rel="noopener noreferrer"
        //     >
        //       {children}
        //     </MaterialUILink>
        //   )
        // }
      },
    },
  };
  return (
    <Layout>
      <SEO
        type="article"
        title={props.data.contentfulArticle.title}
        description={props.data.contentfulArticle.description}
        image={props.data.contentfulArticle.articleCover.fluid.src}
      />
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-8/12">
          <div className="bg-gray-700 rounded-b-md mt-2 pb-4">
            <div className="h-96 bg-gray-100">
              {/* <img
                src={props.data.contentfulArticle.articleCover.fluid.src}
                className="h-96 w-full object-cover object-center "
                alt={props.data.contentfulArticle.articleCover.title}
              /> */}
              <Img
                fluid={props.data.contentfulArticle.articleCover.fluid}
                alt={props.data.contentfulArticle.articleCover.title}
                title={props.data.contentfulArticle.articleCover.title}
                className="h-96 w-full object-cover object-center"
              />
            </div>
            <div className="px-4">
              <h1 className="text-center text-white text-4xl font-bold ">
                {props.data.contentfulArticle.title}
              </h1>
              <div className="flex justify-between items-center py-2">
                <div>
                  <CategoriesRibbon
                    categories={props.data.contentfulArticle.categories}
                  />
                </div>
                <time
                  className="text-xs text-gray-400"
                  dateTime={props.data.contentfulArticle.publishedDate}
                >
                  {new Date(
                    props.data.contentfulArticle.publishedDate
                  ).toLocaleDateString("pt-BR")}
                </time>
              </div>
              {documentToReactComponents(
                props.data.contentfulArticle.body.json,
                options
              )}
            </div>

            <div className="flex justify-center">
              <SocialMediaButtons />
            </div>
          </div>
          <div className="mt-2 flex justify-between">
            <ButtonOutlined text="VOLTAR" to="/" />
          </div>
        </div>
        <div className="w-full lg:w-4/12 mt-2 lg:pl-2 text-white">
          <SideBar />
        </div>
      </div>
    </Layout>
  );
};
export default ArticleTemplate;
